import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

export default ({data}) => {
  const bundleUrl = data.site.siteMetadata.siteUrl + '/ion.jsdos';
  const src = "https://dos.zone/en/player/" + encodeURIComponent(bundleUrl);
  return (
    <Layout>
      <section className="hero is-fullheight-with-nabar">
        <iframe
          className="hero is-fullheight-with-navbar is-fullhd"
          id="jsdos"
          src={src}
        />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`


